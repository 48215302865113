.table-lines[data-v-ee05468c], .table-lines td[data-v-ee05468c], .table-lines th[data-v-ee05468c] {
  border-collapse: collapse;
  border: 1px solid black;
  padding: 0.5rem 0;
}
.active[data-v-ee05468c] {
  background-color: gold;
}
table[data-v-ee05468c] {
  width: 100%;
}