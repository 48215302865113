
.loader-container[data-v-f8da5e5a] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.5;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  filter: alpha(opacity=50);
  z-index: 1000;
}
.loader-content[data-v-f8da5e5a] {
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
