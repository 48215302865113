.body-size[data-v-6e9c9e46] {
  width: 46%;
}
.edit-col[data-v-6e9c9e46] {
  min-width: 70px;
}
.collapse-btn[data-v-6e9c9e46] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0px;
  border-radius: 25%;
}
@media only screen and (max-width: 600px) {
.table-title[data-v-6e9c9e46] {
    position: sticky;
    top: 62px;
    z-index: 10;
    font-size: 2.5vh;
}
}
.back-icon[data-v-6e9c9e46] {
  position: absolute;
  left: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}