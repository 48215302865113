.card-body .row:nth-child(0) div[data-v-fed214ae],
.row:nth-child(1) div[data-v-fed214ae],
.row:nth-child(2) div[data-v-fed214ae],
.row:nth-child(3) div[data-v-fed214ae],
.row:nth-child(4) div[data-v-fed214ae] {
  margin-bottom: 2px;
}
.right-bottom-text[data-v-fed214ae] {
  text-align: right;
}
@media only screen and (max-width: 600px) {
.right-bottom-text[data-v-fed214ae] {
    text-align: left;
}
}
