.collapse-btn[data-v-2d66fd4f] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0px;
  border-radius: 25%;
}
.slide-fade-enter-active[data-v-2d66fd4f] {
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.slide-fade-leave-active[data-v-2d66fd4f] {
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}
.slide-fade-enter[data-v-2d66fd4f], .slide-fade-leave-to[data-v-2d66fd4f] {
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
  opacity: 0;
}