
.img[data-v-2529d779] {
    margin-bottom: 20px;
}
.login-btn[data-v-2529d779] {
    width: 100%;
}
@media only screen and (min-width: 600px) {
.container[data-v-2529d779] {
      width:300px;
      padding: 40px 30px;
}
.desktop-bottom-margin[data-v-2529d779]{
        margin-bottom:35px !important;
}
}
  
  /* BASIC */
body[data-v-2529d779] {
    font-family: "Poppins", sans-serif;
}
a[data-v-2529d779] {
    color: #92badd;
    display: inline-block;
    text-decoration: none;
    font-weight: 400;
}
h2[data-v-2529d779] {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    margin: 40px 8px 10px 8px;
    color: #cccccc;
}
  
  /* STRUCTURE */
.img-wrap[data-v-2529d779] {
    margin: 10px 0;
    border-bottom-left-radius: 50% 25%;
    border-bottom-right-radius: 50% 25%;
    border-top-left-radius: 50% 25%;
    border-top-right-radius: 50% 25%;
}
.wrapper[data-v-2529d779] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    min-height: 100%;
}
#formContent[data-v-2529d779] {
    border-radius: 10px 10px 10px 10px;
    background: #fff;
    padding: 30px;
    width: 90%;
    max-width: 450px;
    position: relative;
    padding: 0px;
    text-align: center;
}
#formFooter[data-v-2529d779] {
    background-color: #f6f6f6;
    border-top: 1px solid #dce8f1;
    padding: 25px;
    text-align: center;
    border-radius: 0 0 10px 10px;
}
  
  /* TABS */
h2.inactive[data-v-2529d779] {
    color: #cccccc;
}
h2.active[data-v-2529d779] {
    color: #0d0d0d;
    border-bottom: 2px solid #5fbae9;
}
  
  /* ANIMATIONS */
  
  /* Simple CSS3 Fade-in-down Animation */
.fadeInDown[data-v-2529d779] {
    -webkit-animation-name: fadeInDown-2529d779;
    animation-name: fadeInDown-2529d779;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
@-webkit-keyframes fadeInDown-2529d779 {
0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
}
100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
}
}
@keyframes fadeInDown-2529d779 {
0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
}
100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
}
}
  
  /* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn-2529d779 {
from {
      opacity: 0;
}
to {
      opacity: 1;
}
}
@keyframes fadeIn-2529d779 {
from {
      opacity: 0;
}
to {
      opacity: 1;
}
}
.fadeIn[data-v-2529d779] {
    opacity: 0;
    -webkit-animation: fadeIn-2529d779 ease-in 1;
    animation: fadeIn-2529d779 ease-in 1;
  
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
}
.fadeIn.first[data-v-2529d779] {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}
.fadeIn.second[data-v-2529d779] {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
}
.fadeIn.third[data-v-2529d779] {
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
}
.fadeIn.fourth[data-v-2529d779] {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}
  
  /* Simple CSS3 Fade-in Animation */
.underlineHover[data-v-2529d779]:after {
    display: block;
    left: 0;
    bottom: -10px;
    width: 0;
    height: 2px;
    background-color: #56baed;
    content: "";
    -webkit-transition: width 0.2s;
    transition: width 0.2s;
}
.underlineHover[data-v-2529d779]:hover {
    color: #0d0d0d;
}
.underlineHover[data-v-2529d779]:hover:after {
    width: 100%;
}
  
  /* OTHERS */
*[data-v-2529d779]:focus {
    outline: none;
}
#icon[data-v-2529d779] {
    width: 50%;
}
  
  /*Sorting icons*/
.sort-icon-both[data-v-2529d779]::after {
    content: url("/static/img/icons/sort10.png");
}
.sort-icon-up[data-v-2529d779]::after {
    content: url("/static/img/icons/sort-up10.png");
}
.sort-icon-down[data-v-2529d779]::after {
    content: url("/static/img/icons/sort-down10.png");
}