
.gainstroke-1[data-v-11315973] {
  background-color: rgba(196, 0, 0, 0.8);
  color: white;
}
.gainstroke-2[data-v-11315973] {
  background-color: rgb(0, 0, 255, 0.8);
  color: white;
}
.toPar[data-v-11315973] {
  position: absolute;
  font-size: 0.8rem;
  -webkit-transform: translateY(1.2rem);
          transform: translateY(1.2rem);
}
@media all and (min-width: 768px) {
.toPar[data-v-11315973] {
    position: absolute;
    font-size: 1rem;
    -webkit-transform: translateY(1rem) translateX(0.5rem);
            transform: translateY(1rem) translateX(0.5rem);
}
}
