th[data-v-18082fe2],
td[data-v-18082fe2] {
  width: 90px;
}
.table-title[data-v-18082fe2] {
  font-size: 20px;
}
.stick-box[data-v-18082fe2] {
  position: sticky;
  top: 62px;
  z-index: 10;
}
.stick-title-box[data-v-18082fe2] {
  position: relative;
}
.stick-title[data-v-18082fe2] {
  display: inline-block;
  width: 80%;
}
@media only screen and (max-width: 600px) {
.stick-title-box[data-v-18082fe2] {
    font-size: 2.5vh;
}
}