.scoretable[data-v-311ee62f] {
  overflow: scroll;
}
th[data-v-311ee62f],
td[data-v-311ee62f] {
  min-width: 80px;
}
.footnote[data-v-311ee62f] {
  font-size: small;
  font-style: italic;
}
tr > td[data-v-311ee62f],
tr > th[data-v-311ee62f] {
  vertical-align: middle;
}
.col-items-spread-out[data-v-311ee62f] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.collapse-btn[data-v-311ee62f] {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  border-radius: 25%;
}
.slide-fade-enter-active[data-v-311ee62f] {
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
/* .slide-fade-leave-active {
  transition: all .1s ease-out;
} */
.slide-fade-enter[data-v-311ee62f], .slide-fade-leave-to[data-v-311ee62f]
/* .slide-fade-leave-active for below version 2.1.8 */ {
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
  opacity: 0;
}
.high-light[data-v-311ee62f] {
  background-color: #ffd275;
}
