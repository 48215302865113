
.pagination[data-v-c6c5ef5c] {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.body[data-v-c6c5ef5c] {
  min-height: 75vh;
  margin-top: 77.5px;
}
@media screen and (max-width: 600px) {
.body[data-v-c6c5ef5c] {
    min-height: 70vh;
    margin-top:77.5px;
}
}
