.page-number[data-v-09512f3f] {
  z-index: 10;
  opacity: 1;
}
@media only screen and (max-width: 600px) {
.page-container[data-v-09512f3f] {
    bottom: -6px;
    z-index: 10;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 92vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 10px 20px;
}
}