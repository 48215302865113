.title[data-v-1b76a8d3] {
  text-align: center;
}
.spread-out[data-v-1b76a8d3] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.action-col[data-v-1b76a8d3] {
  min-width: 150px;
}
