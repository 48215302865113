
.score[data-v-0839ea34] {
  background-color: #fffc6a;
}
.par[data-v-0839ea34] {
  background-color: #d3d3d3;
}
.hole[data-v-0839ea34] {
  background-color: #deb887;
}
.head th[data-v-0839ea34] {
  background-color: #DCD3A0;
}
.table-lines[data-v-0839ea34],.table-lines td[data-v-0839ea34],.table-lines th[data-v-0839ea34] {
  border-collapse: collapse;
  border: 1px solid black;
}
