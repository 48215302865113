
.nav-location[data-v-4295d220] {
  position: fixed;
  z-index: 100;
  top: 0px;
  width: 100%;
}
.green-button[data-v-4295d220] {
  background-color: #19a020;
  color: white;
  border: none;
  margin: 5px;
  font-size: 12px;
  z-index: 10;
}
.back-to-scoring[data-v-4295d220] {
  margin-right: 5px;
  position: absolute;
  right: 80px;
}
.b-navbar-nav[data-v-4295d220] {
  z-index: 20;
}
.active[data-v-4295d220] {
  border-bottom: 1px solid grey;
  font-weight: bold;
}
.navItem[data-v-4295d220] {
  margin-left: 5px;
  margin-right: 5px;
  border-bottom: 1px solid transparent;
}
.navItem[data-v-4295d220]:hover {
  border-bottom: 1px solid black;
}
@media (max-width: 992px) {
.ml-auto .navItem[data-v-4295d220]:first-child {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
}
.ml-auto .navItem[data-v-4295d220]:last-child {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
}
}
