.datepicker[data-v-07bf9ed6] label {
  border: none;
  display: inline-block; 
  vertical-align: middle; 
  width: auto; 
  height: auto;
  margin: 0;
}
.datepicker[data-v-07bf9ed6] .btn {
  height: 100% !important;
  padding: 0%;
}