.collapse-btn[data-v-bca37d62] {
  position: absolute;
  right: 5px;
  padding: 5px;
  border-radius: 25%;
}
.slide-fade-enter-active[data-v-bca37d62] {
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

/* .slide-fade-leave-active {
    transition: all .1s ease-out;
  } */
.slide-fade-enter[data-v-bca37d62], .slide-fade-leave-to[data-v-bca37d62] {
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
  opacity: 0;
}
.btn-size[data-v-bca37d62] {
  min-width: 150px;
}