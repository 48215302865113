
.footer-text[data-v-f906bf10] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.footer[data-v-f906bf10] {
  min-height: 120px;
  position: relative;
}
@media screen and (max-width: 600px) {
.footer[data-v-f906bf10] {
    min-height: 100px;
    position: relative;
}
}
