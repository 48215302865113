@media only screen and (max-width: 600px) {
.table-title[data-v-667a31cd] {
    position: sticky;
    top: 62px;
    z-index: 10;
}
}
.back-icon[data-v-667a31cd] {
  position: absolute;
  left: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}