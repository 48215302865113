.table-lines[data-v-79464398], .table-lines td[data-v-79464398], .table-lines th[data-v-79464398] {
  border-collapse: collapse;
  border: 1px solid black;
  padding: 0.5rem 0;
}
td[data-v-79464398] {
  width: 33.3%;
}
table[data-v-79464398] {
  width: 100%;
}
.winner[data-v-79464398] {
  background-color: gold;
}
.dormie[data-v-79464398] {
  color: brown;
}
.up[data-v-79464398] {
  color: green;
}
.down[data-v-79464398] {
  color: red;
}